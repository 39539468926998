<template>
    <div class="main-box">
        <el-scrollbar class="main-wrapper">
            <StudentAnswerDetail v-if="Object.keys(answerData).length > 0" :answerData="answerData"/>
            <div v-else style="text-align: center;margin-top: 40px">暂无数据</div>
        </el-scrollbar>
    </div>
</template>

<script>
    import {jding_detail} from '@/utils/apis'
    import StudentAnswerDetail from '@/components/evaluation/StudentAnswerDetail.vue'

    export default {
        name: "TheoryList",
        components: {
            StudentAnswerDetail
        },
        data() {
            return {
                examId: this.$route.query.examId, // 考试id
                id: this.$route.query.id, // 学生id
                answerData: {}
            }
        },
        mounted() {
            this.getTheoryExamAnswerDetail();
        },
        methods: {
            getTheoryExamAnswerDetail() {
                let params = {
                    id: this.examId,
                    user_id: this.id
                }
                jding_detail(params).then((res) => {
                    for (let i in res.data.topics) {
                        res.data.topics[i].list.forEach(item => {
                            this.$set(item, 'isAnalysisShow', false)
                        })
                    }
                    this.answerData = res.data;
                }).catch((err) => {
                    console.log('err', err)
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .main-box {
        height: 100%;
    }

    .main-wrapper {
        height: 100%;

        ::v-deep > .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
</style>